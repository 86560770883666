var $ = require('../internals/export');
var DESCRIPTORS = require('../internals/descriptors');
var create = require('../internals/object-create');

// `Object.create` method
// https://tc39.es/ecma262/#sec-object.create
$({
  target: 'Object',
  stat: true,
  sham: !DESCRIPTORS
}, {
  create: create
});